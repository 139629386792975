<template>
  <div class="w-full mx-auto sm:max-w-2xl p-4">
    <div
        v-if="!isLoading && userAssessmentToComplete">

      <AnswerForm
          :user-assessment="userAssessmentToComplete"
          :entity-guid="entityGuid"
          :readonly="readonly"
          :assessor="userAssessmentToComplete!.assessor"
          :delegate="userAssessmentToComplete!.delegate"
          :assessment-display-object="assessmentExtensions.castToAssessmentDisplayObject(userAssessmentToComplete!.assessment)"
          :show-header="true"
          @saved="async () => await goHome()"
          @exit="async () => await goHome()"
      />
    </div>

    <div class="flex justify-center w-full">
      <LoadingCard :is-loading="isLoading"/>
    </div>

    <PopupErrorMessage
        v-if="error"
        :message="error"
        @closed="async () => await goHome()"
    />

    <PopupConfirmation
        v-if="isCompleted"
        title="Assessment Completed"
        @cancel="goHome"
        @confirm="goHome">
    >

      The assessment you are attempting to complete has already been completed. Please press confirm to go to the home screen to view any outstanding assessments to complete
    </PopupConfirmation>
  </div>
</template>

<script setup lang="ts">

import {onMounted, ref} from "vue";
import {AssessmentProxy} from "@/areas/assessments/proxy/assessmentProxy";
import {useRoute, useRouter} from "vue-router";
import {UserAssessmentDto} from "@/areas/assessments/model/dtos/UserAssessmentDto";
import PopupErrorMessage from "@/shared/components/PopupErrorMessage.vue";
import AnswerForm from "@/areas/userAssessments/components/AnswerForm.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";
import Toast from "@/Toast";
import {toastOptions} from "@/areas/helpers/ToastOptions";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";

const route = useRoute()
const router = useRouter()

const assessmentExtensions = new AssessmentDOExtensions()

const entityGuid = ref(route.params.entityGuid as string)

const assessmentGuid = ref(route.params.assessmentGuid as string)

const delegateGuid = ref(route.params.delegateGuid as string)

const assessorGuid = ref(route.params.assessorGuid as string)

const error = ref<string | undefined>(undefined);

const readonly = ref<boolean>(false)

const isLoading = ref<boolean>(true)

const isCompleted = ref<boolean>(false)

const userAssessmentToComplete = ref<UserAssessmentDto | undefined>(undefined)

const goHome = async (success: boolean = false) => {
  error.value = undefined
  isLoading.value = true

  if (success) {
    Toast.success("Successfully submitted your changes", toastOptions)
  }

  const timeout: number = success ? 1000 : 0

  setTimeout(async () =>{
    await router.push({
      name: 'UserAssessments',
      params: {
        entityGuid: entityGuid.value
      }
    })
  }, timeout)

  isLoading.value = true
}

onMounted(async () => {
  const assessmentProxy = new AssessmentProxy();

  const fetchResult = await assessmentProxy.listByUser(entityGuid.value, [], [])

  if (!fetchResult.isSuccessful) {
    error.value = "Could not retrieve the assessment at this time. Please try again later."
    return
  }

  const userAssessments = fetchResult.content!

  const userAssessment = userAssessments.find(uas =>
      uas.assessment.guid == assessmentGuid.value &&
      uas.assessor.guid == assessorGuid.value &&
      uas.delegate.guid == delegateGuid.value);

  if (!userAssessment) {
    error.value = "The assessment you are looking for could not be found at this stage. Please contact support asap"
    return
  }

  if (!([AssessorState.Accepted, AssessorState.InProgress].includes(userAssessment.assessor.entityState!))) {
    readonly.value = true
  }

  if (userAssessment.assessor.entityState == AssessorState.Completed) {
    isCompleted.value = true
  } else {
    userAssessmentToComplete.value = userAssessment;
  }
  isLoading.value = false
});

</script>