<template>
  <Card class="space-y-2">
    <div class="flex flex-col space-y-2 w-full items-center justify-center touch-manipulation">
      <div class="text-payne-grey items-center text-center">
        {{ parseText(getStatement().head!) }}
      </div>

      <div v-if="showHeart" class="text-olive-green items-center text-center">
        {{ parseText(getStatement().heart!) }}
      </div>
    </div>

    <div class="md:space-y-2 pt-2">

      <div class="flex justify-between text-xs text-payne-grey md:px-2">
        <span>{{ lowestHeading }}</span>
        <span>{{ highestHeading }}</span>
      </div>

      <div class="hidden md:flex md:flex-row md:justify-between">
        <v-tooltip v-for="number in 10" :key="number" location="bottom" :text="ratingDescriptions[number]">
          <template v-slot:activator="{ props }">
            <button
                v-bind="props"
                :key="number"
                class="py-2 px-4 border border-gray-300 rounded-full shadow text-payne-grey touch-manipulation"
                :class="{
                  'bg-olive-green text-white': rating === number,
                  'hover:bg-olive-green hover:text-white': !readonly,
                  'cursor-not-allowed': readonly }"
                @click="rate(number)">
              {{ number }}
            </button>
          </template>
        </v-tooltip>
      </div>

      <div class="md:hidden">
        <v-slider
            v-model="rating"
            :min="0"
            :max="10"
            :step="1"
            :disabled="readonly"
            ticks="always"
            tick-size="4"
            class="my-2"
            thumb-label
            always-dirty
            color="payne-grey"
        ></v-slider>
      </div>
    </div>

    <div class="flex flex-row justify-around md:hidden touch-manipulation">
      <div
          @click="decrement()"
          class="text-olive-green rounded-full flex outline-none border-0 items-center justify-center p-2 active:scale-150 transition duration-100">
        <button :disabled="readonly">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 12H19.25" stroke="#53687B" stroke-width="2.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <div class="border-gray-200 border-2 rounded-md w-1/4 flex flex-col justify-center items-center text-payne-grey">
        {{ rating }}
      </div>

      <div
          @click="increment()"
          class="text-olive-green rounded-full flex outline-none border-0 items-center justify-center p-2 active:scale-150 transition duration-100">
        <button :disabled="readonly">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.625 3C11.9234 3 12.2095 3.11853 12.4205 3.3295C12.6315 3.54048 12.75 3.82663 12.75 4.125V10.5H19.125C19.4234 10.5 19.7095 10.6185 19.9205 10.8295C20.1315 11.0405 20.25 11.3266 20.25 11.625C20.25 11.9234 20.1315 12.2095 19.9205 12.4205C19.7095 12.6315 19.4234 12.75 19.125 12.75H12.75V19.125C12.75 19.4234 12.6315 19.7095 12.4205 19.9205C12.2095 20.1315 11.9234 20.25 11.625 20.25C11.3266 20.25 11.0405 20.1315 10.8295 19.9205C10.6185 19.7095 10.5 19.4234 10.5 19.125V12.75H4.125C3.82663 12.75 3.54048 12.6315 3.3295 12.4205C3.11853 12.2095 3 11.9234 3 11.625C3 11.3266 3.11853 11.0405 3.3295 10.8295C3.54048 10.6185 3.82663 10.5 4.125 10.5H10.5V4.125C10.5 3.82663 10.6185 3.54048 10.8295 3.3295C11.0405 3.11853 11.3266 3 11.625 3Z"
                fill="#53687B"/>
          </svg>
        </button>
      </div>
    </div>

    <div v-if="validate && rating == 0">
      <div class="text-red w-full text-center bg-red-lighten-5">
        Please provide a rating for this question
      </div>
    </div>

  </Card>
</template>

<script setup lang="ts">

import Card from "@/shared/components/Card.vue";
import {Ref, ref, watch} from "vue";
import {QuestionDo} from "@/areas/userAssessments/models/dos/QuestionDo";
import keycloak from "@/keycloak";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {useUserStore} from "@/areas/users/store/userStore";
import {User} from "@/areas/users/model/data/User";
import {StatementDto} from "@/areas/assessmentTypes/model/data/dto/StatementDto";
import {UserAssessmentExtensions} from "@/areas/userAssessments/extensions/UserAssessmentExtensions";

const props = defineProps<{
  question: QuestionDo,
  delegate: DelegateDto
  individual?: User
  validate: boolean
  readonly: boolean
  showHeart: boolean
  lowestHeading: string
  highestHeading: string
}>()

const emits = defineEmits<{
  rate: [number]
}>()

const ratingDescriptions: Record<number, string> = {
  1: "Never",
  2: "Almost never",
  3: "Rarely",
  4: "Occasionally",
  5: "Sometimes",
  6: "Fairly often",
  7: "Often",
  8: "Usually",
  9: "Almost always",
  10: "Always"
};

const userStore = useUserStore();

const rating: Ref<number> = ref(props.question.rating ?? 0)

const increment = () => {
  if (rating.value < 10) rating.value++
}

const decrement = () => {
  if (rating.value > 0) rating.value--
}

const rate = (number: number) => {
  if (!props.readonly && number > 0 && number <= 10) {
    rating.value = number
  }
}

const getStatement = (): StatementDto => {
  const loggedInUser = keycloak.tokenParsed;

  if (props.delegate.delegateType == DelegateType.Individual && props.delegate.individual?.guid == loggedInUser!.sub) {
    return props.question.first!
  } else {
    return props.question.third!
  }
}

const parseText = (someString: string): string => {
  return UserAssessmentExtensions.parseText(someString, props.delegate!, userStore.state?.activeEntity!, props.individual)
}

watch(() => rating.value, () => {
  emits('rate', rating.value)
}, {immediate: true})

</script>