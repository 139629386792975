import {defineStore} from "pinia";
import {AssessmentProxy} from "@/areas/assessments/proxy/assessmentProxy";
import {AssessorDto} from "@/areas/assessors/models/dtos/assessorDto";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {AssessorProxy} from "@/areas/assessors/proxies/AssessorProxy";
import {DelegateProxy} from "@/areas/delegates/proxy/delegateProxy";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";
import {AssessmentTypeProxy} from "@/areas/assessmentTypes/proxy/assessmentTypeProxy";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import {AssessorRelationshipType} from "@/areas/assessors/models/enums/AssessorRelationshipType";
import {ReportAssessment} from "@/areas/reports/models/data/ReportAssessment";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {DomainType} from "@/areas/assessmentTypes/model/data/enums/DomainType";
import {ReportDomain} from "@/areas/reports/models/data/ReportDomain";
import {DomainDto} from "@/areas/assessmentTypes/model/data/dto/DomainDto";
import {ReportQuestion} from "@/areas/reports/models/data/ReportQuestion";
import {QuestionDto} from "@/areas/assessmentTypes/model/data/dto/QuestionDto";
import {QuestionResultDto} from "@/areas/userAssessments/models/dtos/QuestionResultDto";
import {DomainResultDto} from "@/areas/userAssessments/models/dtos/DomainResultDto";
import {CommentResultDto} from "@/areas/userAssessments/models/dtos/CommentResultDto";
import {CommentResultType} from "@/areas/userAssessments/models/enums/CommentResultType";
import {forEach, sortBy} from "lodash";
import {ReportManager} from "@/areas/reports/services/ReportManager";
import {EntityProxy} from "@/areas/entities/proxy/entityProxy";
import {Entity} from "@/areas/entities/model/data/dtos/entity";

interface ReportStore {
    assessment: AssessmentDisplayObject | undefined,

    reportManagers: ReportManager[],

    assessmentType: AssessmentTypeDto | undefined,

    entityGuid: string | undefined,

    entity: Entity | undefined;

    isLoading: boolean

    error?: string
}

export interface ReportStats {
    totalAssessors: number,

    totalCompletedAssessors: number,

    totalCompletedDirect: number,

    totalCompletedPeers: number,

    totalCompletedLeader: number
}

function emptyState(): ReportStore {
    return {
        assessment: undefined,

        reportManagers: [],

        assessmentType: undefined,

        entity: undefined,

        entityGuid: undefined,

        isLoading: true,

        error: undefined,
    }
}

export const getDomainQuestionResults = (domain: ReportDomain): number[] => {
    return domain.reportQuestions.flatMap(q => q.resultsOther)
}

export const useReportStore = defineStore({
    id: "reportStore",
    state: (): ReportStore => emptyState(),
    actions: {
        async init(entityGuid: string, assessmentGuid: string, delegateGuid?: string) {
            await this.withLoading(async () => {
                this.clearStore()

                //Fetch Assessment
                const assessmentProxy = new AssessmentProxy();

                const assessmentFetchResult = await assessmentProxy.getByGuid(entityGuid, assessmentGuid, true)

                if (!assessmentFetchResult.isSuccessful) {
                    this.error = "The assessment could not be found"
                    return
                }

                const assessmentExtensions = new AssessmentDOExtensions()

                this.assessment = assessmentExtensions.castToAssessmentDisplayObject(assessmentFetchResult.content!)

                //Fetch Assessment Type

                const assessmentTypeGuid = this.assessment.assessment.entityAssessmentType!.assessmentType.guid!

                const assessmentTypeFetchResult = await AssessmentTypeProxy.get(entityGuid, assessmentTypeGuid)

                if (!assessmentTypeFetchResult.isSuccessful) {
                    this.error = "The assessment type could not be found"
                    return
                }

                this.assessmentType = assessmentTypeFetchResult.content!

                //Fetch delegates
                var delegates: DelegateDto[] = []

                const delegateProxy = new DelegateProxy();

                if (delegateGuid != null) {
                    const delegateFetchResult = await delegateProxy.get(entityGuid, assessmentGuid, delegateGuid)

                    if (!delegateFetchResult.isSuccessful) {
                        this.error = "The delegate could not be found"
                        return;
                    }

                    delegates.push(delegateFetchResult.content!)
                } else {
                    const delegateFetchResult = await delegateProxy.list(entityGuid, assessmentGuid)

                    if (!delegateFetchResult.isSuccessful) {
                        this.error = "Could not fetch the delegates for the result"
                        return;
                    }

                    delegates = delegateFetchResult.content!
                }

                //Build Managers
                const assessorProxy = new AssessorProxy();

                for (const delegate of delegates) {
                    const assessorResults = await assessorProxy.listResultsByDelegate(entityGuid, assessmentGuid, delegate.guid!);

                    if (!assessorResults.isSuccessful) {
                        this.error = "The assessors could not be found"
                        return
                    }

                    const reportManager = new ReportManager(delegate, assessorResults.content!)

                    await reportManager.setReportAssessment(this.assessmentType!, this.assessment!, entityGuid)

                    this.reportManagers.push(reportManager)
                }

                const entityProxy = new EntityProxy();

                const entityFetchResult = await entityProxy.fetchEntity(entityGuid);

                if (!entityFetchResult.isSuccessful) {
                    this.error = "Could not fetch entity information at this stage"
                    return;
                }

                this.entity = entityFetchResult.content!

                this.entityGuid = entityGuid
            })
        },

        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        clearStore() {
            this.$state = emptyState()
        },
    },

})