import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ReportDomain} from "@/areas/reports/models/data/ReportDomain";


export class IvolveCharts {

    static feedbackChart(chartRef: HTMLCanvasElement, welcomeScores: number[]): Promise<string> {
        return new Promise((resolve, reject) => {
            const counts: number[] = Array(10).fill(0)

            welcomeScores.forEach(num => {
                if (num >= 1 && num <= 10) {
                    counts[num - 1]++;
                }
            });

            const ctx = chartRef.getContext('2d')

            if (!ctx) {
                document.body.removeChild(chartRef);
                reject("Unable to get canvas context");
                return;
            }

            const chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                    datasets: [{
                        label: 'Count',
                        backgroundColor: '#698C9A',
                        data: counts
                    }]
                },
                plugins: [ChartDataLabels],
                options: {
                    responsive: false,
                    maintainAspectRatio: true,
                    devicePixelRatio: 2,
                    animation: {
                        duration: 1500,
                        onComplete: function (animation) {
                            const base64 = chart.toBase64Image('image/png');
                            resolve(base64);
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        datalabels: {
                            align: 'center',
                            display: function (context: any) {
                                return context.dataset.data[context.dataIndex] != 0
                            },
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    },
                                    color: 'white'
                                }
                            }
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1
                            },
                            title: {
                                display: true,
                                text: 'Count',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        },
                        x: {
                            title: {
                                display: true,
                                text: 'Rating',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        }
                    }
                }
            })
        })
    }

    static selfVsAssessor(chartRef: HTMLCanvasElement, domain: ReportDomain, isIndividual: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            const labels = domain.reportQuestions.map(rq => rq.question.key)

            const mineValue = domain.reportQuestions.find(rq => rq.resultsMine && rq.resultsMine != 0)

            const hasMine = !!(mineValue)

            let isChartReady = false

            const assessorSet = [{
                backgroundColor: '#9B1B1F',
                data: domain.reportQuestions.map(rq => rq.resultsOtherAverage.toFixed(1)),
                label: isIndividual ? "Assessors' Average Score" : "Average Score"
            }]

            if (hasMine && isIndividual) {
                assessorSet.push({
                    backgroundColor: '#7D983C',
                    data: domain.reportQuestions.map(rq => rq.resultsMine!.toFixed(1)),
                    label: "Self-score"
                })
            }

            const ctx = chartRef.getContext('2d')

            if (!ctx) {
                reject("Unable to get canvas context")
                return;
            }

            let chart: Chart<"bar" | "line" | "scatter" | "bubble" | "pie" | "doughnut" | "polarArea" | "radar", string[], string>;

            chart = new Chart(ctx, {
                type: 'bar',
                plugins: [ChartDataLabels],
                data: {
                    labels: labels,
                    datasets: assessorSet
                },
                options: {
                    responsive: true,
                    devicePixelRatio: 2,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'bottom'
                        },
                        datalabels: {
                            align: 'center',
                            display: function (context: any) {
                                return context.dataset.data[context.dataIndex] != 0
                            },
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    },
                                    color: 'white'
                                }
                            }
                        }
                    },
                    animation: {
                        onComplete: function (animation) {
                            isChartReady = true
                        },
                    },
                    scales: {
                        y: {
                            min: 0,
                            max: 10,
                            beginAtZero: true,
                            ticks: {
                                stepSize: 2
                            },
                            title: {
                                display: true,
                                text: 'Score',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        },
                    }
                }
            })

            const interval = setInterval(() => {
                if (isChartReady) {
                    clearInterval(interval); // Stop checking once ready
                    try {
                        chart.update()
                        const base64 = chart.toBase64Image('image/png');
                        resolve(base64);
                    } catch (error) {
                        reject(error);
                    }
                }
            }, 100);
        })
    }


    static otherRatingCount(chartRef: HTMLCanvasElement, domain: ReportDomain): Promise<string> {
        return new Promise((resolve, reject) => {
            const colours = [
                "#2e2e40",
                "#607349",
                "#8ca1ad",
                "#d2d7df",
                "#aac48c",
                "#7a272a"]

            const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

            let dataSet: any[] = []

            domain.reportQuestions.map(rq => {
                let values: number[] = []

                labels.map(l => {
                    values.push(rq.resultsOther.filter(r => r == l).length)
                })

                dataSet.push({
                    backgroundColor: colours[dataSet.length],
                    data: values,
                    label: rq.question.key
                })
            })

            const ctx = chartRef.getContext('2d')

            if (!ctx) {
                reject("Unable to get canvas context")
                return;
            }

            const chart = new Chart(ctx, {
                type: 'bar',
                plugins: [ChartDataLabels],
                data: {
                    labels: labels,
                    datasets: dataSet
                },
                options: {
                    responsive: true,
                    devicePixelRatio: 2,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'bottom'
                        },
                        datalabels: {
                            align: 'center',
                            display: function (context: any) {
                                return context.dataset.data[context.dataIndex] != 0
                            },
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    },
                                    color: 'white'
                                }
                            }
                        }
                    },
                    animation: {
                        onComplete: function (animation) {
                            const base64 = chart.toBase64Image('image/png');
                            resolve(base64);
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Rating',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        },
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1
                            },
                            title: {
                                display: true,
                                text: 'Count',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        },
                    }
                }
            })
        })
    }
}