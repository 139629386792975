<template>
  <Card class="space-y-2">
    <div class="flex flex-col items-center">

      <div class="text-xl font-semibold text-payne-grey font-roboto">
        {{ delegate.displayName }}
      </div>

      <div class="text-sm font-roboto text-olive-green font-semibold">
        {{ delegate.delegateDto ? getStatus(delegate.delegateDto.entityState!) : delegate.delegateDisplayState }}
      </div>

    </div>

    <div class="grid grid-cols-9">
      <div class="col-span-3">
        <div class="flex flex-col items-center text-payne-grey">
          <div class="font-semibold">
            Type
          </div>

          <div class="text-base">
            {{ delegate.delegateType }}
          </div>
        </div>
      </div>

      <div class="col-span-3 md:hidden"></div>

      <div class="col-span-3 hidden md:block">
        <div class="flex flex-col items-center text-payne-grey">
          <div class="font-semibold">
            Last Contacted
          </div>

          <div class="text-base">
            {{ lastUpdated() }}
          </div>
        </div>
      </div>

      <div class="col-span-3">
        <div class="flex flex-col items-center text-payne-grey">
          <div class="font-semibold">
            Total Assessors
          </div>

          <div class="text-base">
            {{ delegate.summary?.total ?? 0 }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="delegate.summary" class="w-full flex justify-center items-center hidden-sm-and-down">
      <div class="h-[1px] bg-gray-200 w-4/5"></div>
    </div>

    <div v-if="delegate.summary" class="grid grid-cols-9 hidden-sm-and-down">

      <div class="col-span-3 flex flex-col justify-center items-center space-y-2">
        <div>
          <Pill color="bg-blue-grey-darken-1">Waiting</Pill>
        </div>

        <div class="text-payne-grey">
          {{ delegate.summary!.waiting }}
        </div>
      </div>

      <div class="col-span-3 flex flex-col justify-center items-center space-y-2 ">
        <div>
          <Pill color="bg-deep-orange-darken-1">In Progress</Pill>
        </div>

        <div class="text-payne-grey">
          {{ delegate.summary!.inProgress }}
        </div>
      </div>

      <div class="col-span-3 flex flex-col justify-center items-center space-y-2">
        <div>
          <Pill color="bg-teal-darken-1">Completed</Pill>

        </div>

        <div class="text-payne-grey">
          {{ delegate.summary!.completed }}
        </div>
      </div>

    </div>

    <div class="w-full flex justify-center items-center">
      <div class="h-[1px] bg-gray-200 w-4/5"></div>
    </div>

    <div
        v-if="delegate.delegateType == DelegateType.Individual"
        class="flex flex-col space-y-2 justify-center items-center">
      <v-switch
          class="p-0"
          color="dark-blue"
          label="Allow Delegate to Select Own Assessors"
          hide-details
          :disabled="delegate.delegateDisplayState !== DelegateDisplayState.New"
          :model-value="delegate.selectOwnAssessor"
          @update:modelValue="val => emit('selectOwnAssessor', val ?? false)"
      />
    </div>

    <div
        v-if="delegate.delegateType == DelegateType.Individual"
        class="w-full flex justify-center items-center">
      <div class="h-[1px] bg-gray-200 w-4/5"></div>
    </div>

    <div class="flex flex-row w-full justify-center items-center space-x-8">

      <button
          v-if="assessmentState != AssessmentState.Completed"
          @click="deleteInProgress = true"
          class="text-payne-grey bg-transparent p-2 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded"
          title="Delete Delegate"
      >
        <img src="/img/delete_grey.svg" class="h-4" alt="Delete">
      </button>

      <button
          v-if="assessmentState != AssessmentState.Completed"
          @click="emit('edit')"
          class="bg-transparent p-2 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded"
          title="Edit Assessors"
      >
        <img src="/img/assessors.svg" class="h-4" alt="Add/Edit Assessors">
      </button>

      <button
          v-if="showReminderButton()"
          @click="sendReminderInProgress = true"
          class="text-payne-grey bg-transparent p-2 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded"
          title="Send Reminder"
      >
        <img src="/img/mail.svg" class="h-5" alt="Send Reminder">
      </button>

      <button
          v-if="showReport"
          @click="emit('report')"
          class="bg-transparent p-2 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded"
          title="View Report">

        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M5 15V13M8 15V11M11 15V9M13 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H8.586C8.8512 1.00006 9.10551 1.10545 9.293 1.293L14.707 6.707C14.8946 6.89449 14.9999 7.1488 15 7.414V17C15 17.5304 14.7893 18.0391 14.4142 18.4142C14.0391 18.7893 13.5304 19 13 19Z"
              stroke="#53687B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </button>
    </div>

    <div v-if="delegate.error"
         class="py-2 border-red border-2 bg-red-lighten-1 text-white w-full rounded-md text-center font-semibold">
      {{ delegate.error }}
    </div>
  </Card>

  <PopupConfirmation
      v-if="deleteInProgress"
      :title="`Remove ${delegate.displayName}`"
      @cancel="deleteInProgress = false"
      @confirm="confirm">

    By clicking on the confirm button below, you will remove {{ delegate.displayName }} permanently. Do you wish to
    continue?
  </PopupConfirmation>

  <PopupConfirmation
      v-if="sendReminderInProgress"
      title="Send Reminder"
      @cancel="sendReminderInProgress = false"
      @confirm="confirmSendReminder">

    You are about to send a reminder to add assessors for {{ delegate.displayName }}. Do you want to proceed

  </PopupConfirmation>

</template>

<script setup lang="ts">
import Card from "@/shared/components/Card.vue";
import {DelegateDisplayItem} from "@/areas/delegates/model/dos/DelegateDisplayItem";
import Pill from "@/shared/components/Pill.vue";
import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";
import {computed, ref} from "vue";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";
import {DelegateDisplayState} from "@/areas/delegates/model/enums/DelegateDisplayState";
import {DelegateState} from "@/areas/delegates/model/enums/delegateState";
import {formatDateDisplay} from "@/areas/helpers/DateHelpers";
import {AssessmentDetailDto} from "@/areas/assessments/model/dtos/assessmentDetailDto";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";

const props = defineProps<{
  delegate: DelegateDisplayItem
  assessmentDetail: AssessmentDetailDto
  assessmentState: AssessmentState
}>()

const emit = defineEmits<{
  selectOwnAssessor: [boolean]
  delete: []
  edit: []
  report: []
  sendReminder: []
}>()

const deleteInProgress = ref(false)

const sendReminderInProgress = ref(false)

const confirm = () => {
  deleteInProgress.value = false

  emit('delete')
}

const lastUpdated = (): string => {
  if (props.delegate.delegateDto?.mails) {
    const sentEmails = props.delegate.delegateDto?.mails.filter(m => m.processedAt != undefined)

    if (sentEmails && sentEmails.length > 0) {
      return formatDateDisplay(sentEmails[0].processedAt!)
    }
  }

  return "Never"
}

const showReport = computed(() => {
  if (props.assessmentDetail) {
    const minAssessor = props.assessmentDetail.minimumAssessors ?? 2

    return (props.delegate.summary?.completed ?? 0) >= minAssessor
  } else {
    return false
  }
})

const showReminderButton = () => {
  const lastProcessedMail = props.delegate.delegateDto?.mails?.find(m => m.processedAt)

  return props.delegate.delegateDisplayState == DelegateDisplayState.Existing &&
      props.delegate.delegateDto &&
      props.delegate.delegateDto.entityState == DelegateState.AwaitingAssessors &&
      !!(lastProcessedMail) &&
      ![AssessmentState.Draft, AssessmentState.Completed, AssessmentState.Cancelled].includes(props.assessmentState)
}

const confirmSendReminder = () => {
  sendReminderInProgress.value = false

  emit('sendReminder')
}

const getStatus = (state: DelegateState): string => {
  switch (state) {
    case DelegateState.Ready:
      return 'Ready'

    case DelegateState.AwaitingAssessors:
      return 'Awaiting Assessors'

    case DelegateState.Removed:
      return 'Removed'
  }
}
</script>