import {defineStore} from 'pinia'
import {AssessmentProxy} from "@/areas/assessments/proxy/assessmentProxy";
import {AssessmentCreateDto} from '../model/dtos/assessmentCreateDto';
import {AssessmentTypeProxy} from "@/areas/assessmentTypes/proxy/assessmentTypeProxy";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {AssessmentDOExtensions} from "@/areas/assessments/extensions/AssessmentDOExtensions";
import {AssessmentDetailValues} from "@/areas/assessments/model/data/AssessmentDetailValues";
import {AssessmentSettingValues} from "@/areas/assessments/model/data/AssessmentSettingValues";
import {AssessmentDetailDto} from "@/areas/assessments/model/dtos/assessmentDetailDto";
import {AssessmentDto} from "@/areas/assessments/model/dtos/assessmentDto";
import {AssessmentState} from "@/areas/assessments/model/enums/assessmentState";

interface IState {
    assessmentProxy: AssessmentProxy

    assessments: AssessmentDisplayObject[]

    assessmentTypes: AssessmentTypeDto[]

    entityGuid: string | undefined,

    isLoading: boolean,

    error?: string
}

export const useAssessmentStore = defineStore({
    id: "assessment",
    state: (): IState => ({
        assessmentProxy: new AssessmentProxy(),
        entityGuid: undefined,
        isLoading: true,
        assessments: [],
        assessmentTypes: []
    }),
    actions: {

        async init(entityGuid: string) {
            await this.withLoading(async () => {
                const extensions = new AssessmentDOExtensions()

                const listResult = await this.assessmentProxy.list(entityGuid)

                if (!listResult.isSuccessful) {
                    this.error = "Could not retrieve a list of assessments"
                    return
                }

                const listTypesResult = await AssessmentTypeProxy.list(entityGuid)

                if (!listTypesResult.isSuccessful) {
                    this.error = "The assessment types could not be retrieved for the entity"
                    return
                }

                this.assessments = listResult.content!
                    .filter(as => as.entityState != AssessmentState.Cancelled)
                    .map(as => extensions.castToAssessmentDisplayObject(as))

                this.assessmentTypes = listTypesResult.content!

                this.entityGuid = entityGuid
            })
        },

        async save(
            entityGuid: string,
            assessmentDetailValues: AssessmentDetailValues,
            assessmentSettingValues: AssessmentSettingValues,
            existingGuid?: string) {

            await this.withLoading(async () => {
                const assessmentDetailDto: AssessmentDetailDto = {
                    title: assessmentDetailValues.title!,
                    startDate: assessmentDetailValues.startDate!,
                    endDate: assessmentDetailValues.endDate!,
                    includeLeader: assessmentSettingValues.includeLeader,
                    minimumAssessors: assessmentSettingValues.limitsEnabled ? assessmentSettingValues.minimumAssessors : undefined,
                    maximumAssessors: assessmentSettingValues.limitsEnabled ? assessmentSettingValues.maximumAssessors : undefined,
                    reminderFrequency: assessmentSettingValues.reminderFrequency ? assessmentSettingValues.reminderFrequency : undefined
                }

                const assessmentDto: AssessmentDto = {
                    assessmentDetails: [assessmentDetailDto],
                    entityState: AssessmentState.Draft,
                    delegates: []
                }

                if (!existingGuid) {
                    const assessmentCreateDto: AssessmentCreateDto = {
                        assessment: assessmentDto,
                        assessmentTypeGuid: assessmentDetailValues.assessmentType?.guid!
                    }

                    const createResult = await this.assessmentProxy.create(entityGuid, assessmentCreateDto)

                    if (!createResult.isSuccessful) {
                        this.error = `Could not create the assessment: ${createResult.error!}`
                    }
                } else {

                    const updateResult = await this.assessmentProxy.update(entityGuid, existingGuid, assessmentDetailDto)

                    if (!updateResult.isSuccessful) {
                        this.error = "Could not update the assessment successfully"
                    }
                }

                if (!this.error) {
                    await this.init(entityGuid)

                    return
                }

            })
        },

        async sendReminders(assessment: AssessmentDisplayObject) {
            const assessmentDisplayObject = this.assessments.find(as => as.guid == assessment.guid)

            if (!assessmentDisplayObject) {
                this.error = "Could not send reminders, the assessment could not be found"
            }

            await this.assessmentProxy.sendReminders(this.entityGuid!, assessmentDisplayObject?.guid!)
        },

        async setState(entityGuid: string, assessment: AssessmentDisplayObject, state: AssessmentState) {
            await this.withLoading(async () => {
                this.error = undefined

                const result = await this.assessmentProxy.setState(
                    entityGuid,
                    assessment.assessment.guid!,
                    state);

                if (!result.isSuccessful) {
                    if (result.error?.includes("IncorrectState")) {
                        await this.init(entityGuid)

                        this.error = "The assessment is not in the correct state to perform this transition"
                    } else {
                        this.error = "Could not set the assessment state"
                        console.error(result.error)
                    }
                }

                if (!this.error) {
                    await this.init(entityGuid)
                }
            })
        },

        clearError() {
            this.error = undefined
        },

        async getByGuid(entityGuid: string, assessmentGuid: string) {
            return await this.assessmentProxy.getByGuid(entityGuid, assessmentGuid);
        },

        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },
    }
});  