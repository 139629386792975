<template>
  <PopupConfirmation
      title="Generating Reports (Do not close this window or the browser)"
      confirmText="Ok"
      :ignore-cancel="true"
      @confirm="emits('ok')"
      @cancel="emits('ok')"
  >
    <div v-if="noReports">
      There are no reports to download for this assessment, please make sure that delegates have been added and a minimum of two assessors have completed the assessment.
    </div>

    <div class="flex flex-col space-y-4" v-if="!noReports">
      <div v-if="isCompleted && isZipping">
        Report Download has been completed. A download has been started
      </div>

      <div v-if="!isCompleted && total">
        <div class="flex flex-col space-y-4">
          <div v-if="isZipping">
            Compressing File... {{getSlotValue()}}
          </div>
          <div v-else>
            Downloading Reports... {{getSlotValue()}}
          </div>

          <v-progress-linear
              :model-value="progress"
              color="olive-green"
              bg-color="olive-green"
              height="25"
              :max="total"
          >
          </v-progress-linear>
        </div>
      </div>
    </div>
  </PopupConfirmation>
</template>

<script setup lang="ts">

import PopupConfirmation from "@/shared/components/PopupConfirmation.vue";

const props = defineProps<{
  total?: number
  progress: number
  isZipping: boolean
  isCompleted: boolean
  noReports: boolean
}>()

const emits = defineEmits<{
  ok: []
}>()

const getSlotValue = (): string => {
  if (!props.total) {
    return ''
  }

  if (props.isZipping) {
    const percentage = Math.round(props.progress / props.total)

    return `${percentage}%`
  }

  return `${props.progress} / ${props.total}`
}

</script>