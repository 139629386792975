import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {ReportAssessment} from "@/areas/reports/models/data/ReportAssessment";
import {ReportStats} from "@/areas/reports/stores/ReportStore";
import {AssessorDto} from "@/areas/assessors/models/dtos/assessorDto";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";
import {AssessorRelationshipType} from "@/areas/assessors/models/enums/AssessorRelationshipType";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {AssessorProxy} from "@/areas/assessors/proxies/AssessorProxy";
import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import {AssessmentDisplayObject} from "@/areas/assessments/model/dos/AssessmentDisplayObject";
import {DomainType} from "@/areas/assessmentTypes/model/data/enums/DomainType";
import {sortBy} from "lodash";
import {CommentResultDto} from "@/areas/userAssessments/models/dtos/CommentResultDto";
import {CommentResultType} from "@/areas/userAssessments/models/enums/CommentResultType";
import {ReportDomain} from "@/areas/reports/models/data/ReportDomain";
import {DomainResultDto} from "@/areas/userAssessments/models/dtos/DomainResultDto";
import {ReportQuestion} from "@/areas/reports/models/data/ReportQuestion";
import {QuestionResultDto} from "@/areas/userAssessments/models/dtos/QuestionResultDto";
import {DomainDto} from "@/areas/assessmentTypes/model/data/dto/DomainDto";
import {QuestionDto} from "@/areas/assessmentTypes/model/data/dto/QuestionDto";

export class ReportManager {

    delegate: DelegateDto

    stats: ReportStats

    reportAssessment: ReportAssessment | undefined

    assessors: AssessorDto[]

    constructor(delegate: DelegateDto, assessors: AssessorDto[]) {
        this.delegate = delegate
        this.assessors = assessors
        this.stats = this.getStats()
    }

    async setReportAssessment (assessmentType: AssessmentTypeDto, assessment: AssessmentDisplayObject, entityGuid: string) {
        this.reportAssessment = await this.buildReportAssessment(assessmentType, assessment, entityGuid)
    }

    getStats(): ReportStats {
        return {
            totalAssessors: this.assessors.filter(ass =>
                ass.entityState != AssessorState.Removed && ass.entityState != AssessorState.Declined).length,

            totalCompletedAssessors: this.assessors.filter(ass =>
                ass.entityState == AssessorState.Completed).length,

            totalCompletedPeers: this.assessors.filter(ass =>
                ass.entityState == AssessorState.Completed &&
                ass.assessorRelationshipType == AssessorRelationshipType.Peer && !this.isDelegate(ass, this.delegate!)).length,

            totalCompletedDirect: this.assessors.filter(ass =>
                ass.entityState == AssessorState.Completed &&
                ass.assessorRelationshipType == AssessorRelationshipType.DirectReport && !this.isDelegate(ass, this.delegate!)).length,

            totalCompletedLeader: this.assessors.filter(ass =>
                ass.entityState == AssessorState.Completed &&
                ass.assessorRelationshipType == AssessorRelationshipType.Leader && !this.isDelegate(ass, this.delegate!)).length
        }
    }

    isDelegate(assessor: AssessorDto, delegate: DelegateDto): boolean {
        return delegate.delegateType == DelegateType.Individual && delegate.individual!.guid == assessor.user!.guid
    }

    async buildReportAssessment(assessmentType: AssessmentTypeDto, assessment: AssessmentDisplayObject, entityGuid: string): Promise<ReportAssessment> {
        let report = this.createEmptyReport(assessmentType, assessment, this.delegate)

        const assessorProxy = new AssessorProxy()


        for (const assessor of this.assessors) {
            let isMine = false

            if (assessor.entityState != AssessorState.Completed) {
                continue
            }

            if (this.delegate.delegateType == DelegateType.Individual && assessor.user?.guid == this.delegate?.individual?.guid) {
                isMine = true
            }

            const commentResults = await assessorProxy.listResultComments(entityGuid, assessment.guid, this.delegate.guid!, assessor.guid!)

            if (!commentResults.isSuccessful) {
                return report
            }

            report = this.mapReportAssessment(report, assessor, commentResults.content!, isMine)
        }

        return report
    }

    createEmptyReport(assessmentType: AssessmentTypeDto, assessment: AssessmentDisplayObject, delegate: DelegateDto): ReportAssessment {
        const domains = assessmentType.domains.filter(d => d.deletedAt == null)

        const welcomeDomain = domains.filter(d => d.domainType == DomainType.Welcome)

        const welcomeReportDomains = welcomeDomain.map(d => this.newReportDomain(d))

        const assessDomains = sortBy(domains.filter(d => d.domainType == DomainType.AssessDomain), "key")

        return {
            welcomeDomain: welcomeReportDomains.length > 0 ? welcomeReportDomains[0] : undefined,
            welcomeTotalAverageMine: 0,
            welcomeTotalAverageOther: 0,
            assessmentDetail: assessment.detail,
            delegate: delegate,
            welcomeOtherComments: [],
            welcomeMineComment: undefined,
            guid: assessment.guid,
            assessDomains: assessDomains.map(d => this.newReportDomain(d)),
            awayOtherComments: [],
            awayMineComment: undefined,
            towardOtherComments: [],
            towardMineComment: undefined,
            mineTotalAverage: 0,
            otherTotalAverage: 0,
        }
    }

    mapReportAssessment(reportAssessment: ReportAssessment, assessor: AssessorDto, resultComments: CommentResultDto[], isMine: boolean): ReportAssessment {

        reportAssessment.assessDomains = reportAssessment.assessDomains.map(reportDomain => {
            const domainResult = assessor.domainResults?.find(dr => dr.domain.guid == reportDomain.domain.guid)

            if (!domainResult) {
                console.error("Found a domain that does not have an answer, this should not be possible: ", assessor.guid)
                throw new DOMException("Cannot find assess domain")
            }

            return this.mapReportDomain(reportDomain, domainResult, isMine)
        })

        //Set the welcome information
        const welcomeDomainResult = assessor.domainResults?.find(d => d.domain.domainType == DomainType.Welcome)

        if (welcomeDomainResult == null) {
            console.error("No welcome domain found")
            throw new DOMException("No welcome domain found")
        }

        reportAssessment.welcomeDomain = this.mapReportDomain(reportAssessment.welcomeDomain!, welcomeDomainResult, isMine)

        //Get the away/towards comments
        const awayComment = resultComments.find(r => r.commentResultType == CommentResultType.Away)
        const towardComment = resultComments.find(r => r.commentResultType == CommentResultType.Toward)

        //Get the completion information
        if (isMine) {
            reportAssessment.welcomeMineComment = welcomeDomainResult.comments

            reportAssessment.awayMineComment = awayComment?.comment
            reportAssessment.towardMineComment = towardComment?.comment

            reportAssessment.welcomeTotalAverageMine = reportAssessment.welcomeDomain!.mineDomainAverage

            reportAssessment.mineTotalAverage = reportAssessment.assessDomains.reduce(
                (average, reportDomain, index) =>
                    ((average * index) + reportDomain.mineDomainAverage!) / (index + 1), 0)
        } else {
            reportAssessment.welcomeOtherComments.push(welcomeDomainResult.comments)

            reportAssessment.awayOtherComments.push(awayComment?.comment!)
            reportAssessment.towardOtherComments.push(towardComment?.comment!)

            reportAssessment.welcomeTotalAverageOther = reportAssessment.welcomeDomain!.otherDomainAverage

            reportAssessment.otherTotalAverage = reportAssessment.assessDomains.reduce(
                (average, reportDomain, index) =>
                    ((average * index) + reportDomain.otherDomainAverage!) / (index + 1), 0)

        }

        //Set the completion information
        return reportAssessment
    }

    mapReportDomain(reportDomain: ReportDomain, domainResult: DomainResultDto, isMine: boolean): ReportDomain {

        reportDomain.reportQuestions = reportDomain.reportQuestions.map(reportQuestion => {
            const question = domainResult.questionResults.find(qr => qr.question.guid == reportQuestion.question.guid)

            if (question == null) {
                console.error("Found a question that does not have an answer, this should not be possible: ", reportQuestion.question.guid, reportDomain.domain.guid)
                throw new DOMException("Cannot find answer")
            }

            return this.mapReportQuestion(reportQuestion, question, isMine)
        })

        //The difference here is the difference in field name
        if (isMine) {
            reportDomain.mineDomainAverage = reportDomain.reportQuestions.reduce(
                (average, reportQuestion, index) =>
                    ((average * index) + reportQuestion.resultsMine!) / (index + 1), 0)

            reportDomain.mineComment = domainResult.comments
        } else {
            reportDomain.otherDomainAverage = reportDomain.reportQuestions.reduce(
                (average, reportQuestion, index) =>
                    ((average * index) + reportQuestion.resultsOtherAverage!) / (index + 1), 0)

            reportDomain.otherComments.push(domainResult.comments)
        }

        return reportDomain
    }

    mapReportQuestion(reportQuestion: ReportQuestion, result: QuestionResultDto, isMine: boolean): ReportQuestion {
        if (!result.rating) {
            return reportQuestion
        }

        if (isMine) {
            reportQuestion.resultsMine = result.rating

            return reportQuestion
        }

        const currentAverage = reportQuestion.resultsOtherAverage
        const currentCount = reportQuestion.resultsOther.length

        reportQuestion.resultsOtherAverage = ((currentAverage * currentCount) + result.rating) / (currentCount + 1)
        reportQuestion.resultsOther.push(result.rating)

        return reportQuestion
    }

    newReportDomain(domainDto: DomainDto): ReportDomain {
        return {
            mineDomainAverage: 0,
            domain: domainDto,
            reportQuestions: domainDto.questions.map(q => this.newReportQuestion(q)),
            otherDomainAverage: 0,
            otherComments: [],
            mineComment: ''
        }
    }

    newReportQuestion(questionDto: QuestionDto): ReportQuestion {
        return {
            resultsMine: 0,
            resultsOther: [],
            resultsOtherAverage: 0,
            question: questionDto
        }
    }
}