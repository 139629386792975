<template>
  <PopupMessageDisplay>
    <LoadingCard :is-loading="reportStore.isLoading"/>
  </PopupMessageDisplay>

  <ReportProgress
      v-if="!reportStore.isLoading"
      :total="progressTotal"
      :progress="progressCount"
      :isZipping="isZipping"
      :isCompleted="isComplete"
      :noReports="noReports"
      @ok="emit('complete')"
  />

  <div v-if="!reportStore.isLoading" v-for="reportManager of reportStore.reportManagers"
       :key="reportManager.delegate.guid" class="invisible">

    <div v-for="domain in reportManager.reportAssessment!.assessDomains">
      <div class="relative h-[225px] w-[1000px]">
        <canvas
            :ref="el => setCanvasRef(`selfVsAssessor_${reportManager.delegate.guid!}_${domain.domain.guid}`, el as HTMLCanvasElement)"/>
      </div>

      <div class="relative h-[225px] w-[1000px]">
        <canvas
            :ref="el => setCanvasRef(`otherRatingCount_${reportManager.delegate.guid!}_${domain.domain.guid}`, el as HTMLCanvasElement)"/>
      </div>

      <div class="relative h-[225px] w-[1000px]">
        <canvas
            :ref="el => setCanvasRef(`feedbackChart_${reportManager.delegate.guid}`, el as HTMLCanvasElement)"></canvas>
      </div>
    </div>

  </div>

  <div class="pt-40"></div>
</template>

<script setup lang="ts">
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import {styles} from './styles'
import {colors} from '@/colors'
import {drawLeadership360} from './OneViewChartL360'
import {all_in_one_svg, generate_circle_svg, header_svg,} from './svgs'
import {escape, sortBy, trim} from 'lodash';
import {contrastingColor, highestScores, lowestScores} from './utils'
import {behaviour_leading_questions} from './constants';
import {data} from "@/components/Atomic/BAtoms/Reports/data";
import {onMounted, ref} from "vue";
import {getDomainQuestionResults, useReportStore} from "@/areas/reports/stores/ReportStore";
import {getBase64Image} from "@/areas/helpers/Base64Helper";
import {format} from "date-fns";
import {ReportDomain} from "@/areas/reports/models/data/ReportDomain";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {IvolveCharts} from "@/areas/reports/charts/IvolveCharts";
import {StatementPerspectiveType} from "@/areas/assessmentTypes/model/data/enums/StatementPerspectiveType";
import {UserAssessmentExtensions} from "@/areas/userAssessments/extensions/UserAssessmentExtensions";
import {useUserStore} from "@/areas/users/store/userStore";
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import PopupMessageDisplay from "@/shared/components/PopupMessageDisplay.vue";
import LoadingCard from "@/shared/components/LoadingCard.vue";
import {ReportManager} from "@/areas/reports/services/ReportManager";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import ReportProgress from "@/areas/reports/components/ReportProgress.vue";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface DomainGraph {
  key: string
  selfVsAssessor: string
  ratingGraph: string
}

const props = defineProps<{
  entityGuid: string
  assessmentGuid: string,
  delegateGuid?: string
}>()

const emit = defineEmits<{
  complete: []
}>()

const canvasRefs = ref<Record<string, HTMLCanvasElement>>({});

const reportStore = useReportStore()

const userStore = useUserStore()

const entity = ref<Entity | undefined>(undefined)

const feedbackGraph = ref<string | undefined>(undefined)

const isIndividual = ref<boolean>(false)

const graphs = ref<DomainGraph[]>([])

const progressCount = ref<number>(0)

const isZipping = ref<boolean>(false)

const progressTotal = ref<number>(0)

const isComplete = ref<boolean>(false)

const noReports = ref<boolean>(false)

const setCanvasRef = (name: string, el: HTMLCanvasElement | null) => {
  if (el) {
    canvasRefs.value[`${name}`] = el;
  }
};

const generateZip = async () => {
  const zip = new JSZip();
  progressTotal.value = reportStore.reportManagers.length;

  if (progressTotal.value === 0) {
    noReports.value = true
  }

  for (const reportManager of reportStore.reportManagers) {
    isIndividual.value = reportManager.delegate.delegateType == DelegateType.Individual;

    const selfGraphsAsync = reportManager.reportAssessment!.assessDomains!.map(async (domain) => {
      const canvasKey = `selfVsAssessor_${reportManager.delegate.guid}_${domain.domain.guid}`
      const canvasElement = canvasRefs.value[canvasKey]

      return await IvolveCharts.selfVsAssessor(canvasElement, domain, isIndividual.value)
    });

    const ratingGraphsAsync = reportManager.reportAssessment!.assessDomains!.map(async (domain) => {
      const canvasKey = `otherRatingCount_${reportManager.delegate.guid}_${domain.domain.guid}`
      const canvasElement = canvasRefs.value[canvasKey]

      return await IvolveCharts.otherRatingCount(canvasElement, domain)
    })

    const selfGraphs = await Promise.all(selfGraphsAsync);
    const ratingGraphs = await Promise.all(ratingGraphsAsync)

    graphs.value = reportManager.reportAssessment!.assessDomains!.map((domain, index) => ({
      key: domain.domain.key,
      selfVsAssessor: selfGraphs[index],
      ratingGraph: ratingGraphs[index],
    }));

    const canvasKey = `feedbackChart_${reportManager.delegate.guid}`
    const canvasElement = canvasRefs.value[canvasKey]

    entity.value = userStore.state?.activeEntity!;
    feedbackGraph.value = await IvolveCharts.feedbackChart(canvasElement, getDomainQuestionResults(reportManager.reportAssessment!.welcomeDomain!));

    const blob = await generatePDF(reportManager);

    console.log("completed await")

    // Create a filename based on assessment type and delegate
    let fileName = `delegate_${reportManager.delegate.displayName}.pdf`;

    fileName = sanitizeFilename(fileName)

    zip.file(fileName, blob)

    progressCount.value++// Emit progress percentage
  }

  isZipping.value = true
  progressTotal.value = 0
  progressCount.value = 0

  // Generate the ZIP file and trigger download
  const zipBlob = await zip.generateAsync({type: "blob"}, (metadata) => {
    progressCount.value = Math.round(metadata.percent)
  });

  saveAs(zipBlob, "iVolve_Reports.zip");

  isComplete.value = true
};

onMounted(async () => {
  await reportStore.init(props.entityGuid, props.assessmentGuid, props.delegateGuid)

  await generateZip()
})

pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

const sanitizeFilename = (filename: string): string => {
  // Define the forbidden characters as per the requirements
  const forbiddenChars = /[#%&{}\\<>*?/ $!'":@+`|=]/g;

  // Separate filename and extension
  const lastDotIndex = filename.lastIndexOf('.');
  const hasExtension = lastDotIndex !== -1 && lastDotIndex !== 0;

  const namePart = hasExtension ? filename.substring(0, lastDotIndex) : filename;
  const extension = hasExtension ? filename.substring(lastDotIndex) : '';

  // Remove forbidden characters from name part
  let sanitized = namePart.replace(forbiddenChars, '');

  // Replace spaces and underscores with hyphens
  sanitized = sanitized.replace(/[\s_]+/g, '-');

  // Remove leading and trailing spaces, periods, hyphens, or underscores
  sanitized = sanitized.replace(/^[\s.\-_]+|[\s.\-_]+$/g, '');

  // Convert to lowercase
  sanitized = sanitized.toLowerCase();

  // Ensure filename length is within 31 characters, preserving the extension
  const maxLength = 31 - extension.length;
  sanitized = sanitized.substring(0, maxLength);

  // Remove any non-ASCII characters
  sanitized = sanitized.replace(/[^\x00-\x7F]/g, '');

  return sanitized + extension;
};

const layout = {
  defaultBorder: false,
  paddingLeft: function () {
    return 0;
  },
  paddingRight: function () {
    return 0;
  },
  paddingBottom: function () {
    return 0;
  },
  paddingTop: function () {
    return 0;
  },
}

const spacing = {
  section: 15,
  subsection: 10,
}

const toc_style = {
  tocItem: true,
  tocStyle: {italics: false, fontSize: 10, color: styles.primary},
  tocMargin: [0, 0, 0, 0],
  tocNumberStyle: {italics: true, fontSize: 10, color: styles.primary},
}

const getAssessmentDisplayDate = () => {
  return format(reportStore.assessment!.detail.endDate, 'MMMM yyyy')
}

const generatePDF = async (reportManager: ReportManager): Promise<Blob> => {

  const towerStoneLogo = await getBase64Image('/img/logo_towerstone.png')
  const iVolveLogo = await getBase64Image('/img/logo_ivolve.png')
  const towerStoneIcon = await getBase64Image('/img/icon_ivolve.png')
  const reportBehaviourChange = await getBase64Image('/img/report_behavioral_change.png')
  const reportBehaviourFramework = await getBase64Image('/img/report_framework.png')
  const reportRatingScale = await getBase64Image('/img/report_rating_scale.png')

  const content = [
    coverPage(reportManager),
    TableOfContents(),
    IntroductionSection(reportManager),
    iVolveLeadershipFrameWork(reportManager),
    Qualities(reportManager),
    FeedbackSection(reportManager),
    BehaviouralFeedback(reportManager),
    await iVolveBehaviourSection(reportManager),
    InterpretingFeedback(reportManager),
  ];

  const pdfDoc = {
    info: {
      title: `${reportStore.assessment?.detail.title}`,
      author: 'iVolve Team',
    },
    header: (currentPage: number, pageCount: number) => {
      return DefaultHeader(currentPage, pageCount)
    },
    pageMargins: [40, 60, 40, 40],
    content,
    images: {
      iVolveFramework: reportBehaviourFramework,
      partnerLogo: iVolveLogo,
      towerstoneLogo: towerStoneLogo,
      feelingScoreCount: 'https://placehold.co/200x100/png?font=roboto&text=Rating+count',
      interpretingFeedback: reportBehaviourFramework,
      assessorsVSSelf: 'https://placehold.co/200x100/png?font=roboto&text=Self+vs+assessors\'s+score',
      ratingCount: 'https://placehold.co/200x100/png?font=roboto&text=Rating+Count\n(Assessors+only)',
      partnerAvatar: towerStoneIcon,
      reportBehaviourChange: reportBehaviourChange,

      feedbackGraph: feedbackGraph.value!,

      reportRatingScale: reportRatingScale,
    },
    defaultStyle: {
      font: 'Roboto'
    },
    styles: styles,
    footer: (currentPage: number, pageCount: any, pageSize: any) => {
      const text = [
        "iVolve",
        `  |  ${reportStore.assessment?.detail.title}`,
        ` report for ${isIndividual.value ? reportManager.delegate.displayName : `the ${reportManager.delegate!.displayName} Team`}`,
        `  |  ${getAssessmentDisplayDate()}`,
      ]
      return Footer(currentPage, text)
    },
  };
  // to auto download the file without 1st opening it in a different tab, use .download('filename.pdf')
  // @ts-ignore
  return new Promise((resolve) => {
    // @ts-ignore
    pdfMake.createPdf(pdfDoc).getBlob((blob: Blob) => {
      resolve(blob);
    });
  });
}

const TableOfContents = () => {
  return [
    {
      text: "Table of Contents",
      style: [styles.section_title],
      pageBreak: "before",
    },
    {
      toc: {
        title: {
          text: ' ',
          style: ["primary"]
        },
        textStyle: ["table_of_contents"],
        numberStyle: {bold: true},
        style: ['primary'],
        fillCharacter: '.'
      }
    },
  ]
}

const coverPage = (reportManager: ReportManager) => {
  let reportTitle =
      `${reportStore.assessmentType?.displayName}\n${isIndividual.value ? 'Report' : 'Report'}`

  let fullName = isIndividual.value ? reportManager.delegate.displayName : `${reportManager.delegate.displayName} Team`

  fullName = `${reportStore.entity?.displayName}: ${fullName}`

  return [
    {
      image: "partnerLogo",
      width: 215,
      margin: [50, 0, 0, 100],
    },
    {
      text: "fresh insights, new beginnings",
      style: [
        {
          fontSize: 16,
          italics: false,
        },
        "secondary",
      ],
      margin: [50, 50, 0, 20],
    },
    {
      text: reportTitle,
      style: ["primary"],
      fontSize: 24,
      margin: [50, 20, 0, 20],
    },
    {
      text: fullName,
      style: ["secondary", {fontSize: 15}],
      margin: [50, 0, 0, 0],
      lineHeight: 1.2,
    },
    {
      text: ``,
      style: ["secondary", {fontSize: 15}],
      margin: [50, 0, 0, 0],
    },
    {
      text: `${getAssessmentDisplayDate()}`,
      style: ["primary", {fontSize: 15}],
      margin: [50, 40, 0, 80],
    },
    {
      text: "In association with",
      style: ["comments"],
      margin: [50, 0, 0, 20],
    },
    {
      image: "towerstoneLogo",
      width: 160,
      margin: [50, 0, 0, 0],
    },
  ];
}

const IntroductionSection = (reportManager: ReportManager) => {
  const number_of_questions = reportManager.reportAssessment?.assessDomains.reduce((total, domain) => total + domain.domain.questions.length, 0);

  const selfTitlePlural = isIndividual.value ? 'your' : 'the team\'s'

  const headingValue = isIndividual.value ? 'you mould behaviours that serve your growth.' : 'you evolve your team by shaping behaviours that serve your team members and your organisation.'

  return [
    {
      text: "Section A: Overview",
      style: [styles.section_title],
      pageBreak: "before",
      ...toc_style,
      tocStyle: {...toc_style.tocStyle, italics: true},
    },
    [
      {
        text: ' ',
        margin: [0, 0, 0, spacing.subsection],
      },
      {
        text: `The iVolve Leadership Assessment Framework`,
        style: [styles.primary, styles.section_subtitle],
        margin: [0, 0, 0, spacing.subsection],
        ...toc_style,
        tocMargin: [10, 0, 0, 0],
      },
      {
        text: [
          `The iVolve Leadership Assessment Framework comprises six domains representing an integral range of `,
          `human and organisational needs in the workplace from `,
          {text: `personal development`, italics: true},
          ` (My Space) to `,
          {text: `team cohesion`, italics: true},
          ` (My Team) to `,
          {text: `cultural alignment`, italics: true},
          ` (Our Culture) to `,
          {text: `achieving results`, italics: true},
          ` (Our Results) to `,
          {text: `growth and innovation`, italics: true},
          ` (Our Growth) to `,
          {text: `organisational purpose`, italics: true},
          ` (Our Purpose).`,
        ],
        style: [styles.primary, styles.section_description, {alignment: "justify"}],
        margin: [0, 0, 0, 7.5],
      },
      {
        text: `Each domain comprises a number of leadership behaviours which are posed as behavioural statements that elicit both head (factual, i.e. can be observed) and heart (emotive, i.e. can be felt) perspectives.`,
        style: [styles.primary, styles.section_description],
      },
    ],
    {
      image: "iVolveFramework",
      width: 500,
      margin: [0, 30, 0, 0],
      style: ["centerAligned"],
    },
    [
      {
        text: "How to use this report",
        style: [styles.primary, styles.section_subtitle],
        margin: [0, 20, 0, spacing.subsection],
        ...toc_style,
        tocMargin: [10, 0, 0, 0],
      },
      {
        text: [
          `The iVolve ${reportStore.assessmentType?.displayName} provides feedback to help ${headingValue}`
        ],
        style: [styles.primary, styles.section_description, {alignment: "justify"}],
        margin: [0, 0, 0, 7.5],
      },
      {
        ul: [
          `A dashboard of your ${isIndividual.value ? '' : 'team\'s '}rating scores for ${number_of_questions} behavioural statements as per the iVolve six-domain Leadership Assessment Framework (see below).`,
          `The highest-rated and lowest-rated behaviours as rated by ${selfTitlePlural} assessors.`,
          `Detailed results for each of the six domains, including rating frequencies.`,
          `Verbatim feedback comments from ${selfTitlePlural} assessors regarding ${selfTitlePlural} relationship health and behaviours that switch them on or off.`,
          `A guide to interpreting your results and adopting new habits to support ${isIndividual.value ? "your growth" : "how you evolve the team"}.`,
        ],
        margin: [10, 0, 0, 0],
        style: [styles.primary, styles.section_description],
      },
    ],
  ];
}

const LeadershipFrameWorkAssessorsSection = (reportManager: ReportManager) => {
  const pluralTitleCapital = isIndividual.value ? "Your" : "The team's"
  const pluralTitleLower = isIndividual.value ? "your" : "the team's"

  const themYour = isIndividual.value ? "your" : "their"

  const totalCompletedAssessors = reportManager.reportAssessment?.mineTotalAverage == 0 ? reportManager.stats?.totalCompletedAssessors : reportManager.stats!.totalCompletedAssessors - 1
  const totalAssessors = reportManager.reportAssessment?.mineTotalAverage == 0 ? reportManager.stats?.totalAssessors : reportManager.stats!.totalAssessors - 1

  const yourAssessorColumns = [{
    svg: generate_circle_svg({
      size: 2.75,
      color: colors.blue,
      text: `${totalCompletedAssessors}`,
      font_size: 16
    }),
    alignment: isIndividual.value ? "left" : "center",
  }]

  const showBreakDown = {
    table: {
      body: [
        [
          {
            svg: generate_circle_svg({
              size: 0.89,
              color: colors.blue,
              text: `${reportManager.stats?.totalCompletedLeader}`
            })
          },
          {
            text: `Leader`,
            style: ["primary", "section_description"],
            margin: [3, 3, 0, 0],
          },
        ],
        [
          {
            svg: generate_circle_svg({
              size: 0.89,
              color: colors.blue,
              text: `${reportManager.stats?.totalCompletedPeers}`
            })
          },
          {
            text: `Peers/Colleagues`,
            style: ["primary", "section_description"],
            margin: [3, 3, 0, 0],
          },
        ],
        [
          {
            svg: generate_circle_svg({
              size: 0.89,
              color: colors.blue,
              text: `${reportManager.stats?.totalCompletedDirect}`
            })
          },
          {
            text: `Direct reports`,
            style: ["primary", "section_description"],
            margin: [3, 3, 0, 0],
          },
        ],
      ],
    },
    layout,
  }

  if (isIndividual.value) {
    //@ts-ignore
    yourAssessorColumns.push(showBreakDown)
  }

  return [
    {
      text: `${isIndividual.value ? `${pluralTitleCapital} assessors` : 'Assessors'}`,
      style: ["primary", "section_subtitle"],
      pageBreak: "before",
      ...toc_style,
      tocMargin: [10, 0, 0, 0],
    },
    {
      text: `${isIndividual.value ? `This report presents the results of ${pluralTitleLower} self-assessment compared with the feedback from ${themYour} nominated assessors.` : ''}`,
      style: ["primary", "section_description"],
      margin: [0, 10, 0, 15],
    },
    {
      columns: [
        [
          {
            text: `Nominated assessors\n\n`,
            style: ["primary", "section_description"],
            alignment: isIndividual.value ? "left" : "center",
          },
          {
            svg: generate_circle_svg({
              size: 2.75,
              color: colors['olive-green'],
              text: `${totalAssessors}`,
              font_size: 16
            }),
            alignment: isIndividual.value ? "left" : "center"
          },
        ],
        [
          {
            text: `Assessors completed\n\n`,
            style: ["primary", "section_description"],
            alignment: isIndividual.value ? "left" : "center",
          },
          {
            columns: yourAssessorColumns,
          },
        ],
      ],
    }
  ]
}

const iVolveLeadershipFrameWork = (reportManager: ReportManager): any[] => {
  return [
    ...LeadershipFrameWorkAssessorsSection(reportManager),
    {
      text: '',
      margin: [0, 0, 0, 40],
    },
    {
      text: "The rating scale",
      style: ["primary", "section_subtitle"],
      tocMargin: [10, 0, 0, 0],
    },
    {
      text: "The 10-point rating scale measures how assessors observe the behaviour described by each statement.",
      style: ["primary", "section_description"],
      margin: [0, 10, 0, 10],
    },
    {
      image: "reportRatingScale",
      width: 495,
      alignment: "left",
      margin: [0, 0, 0, 0],
      border: [false, false, false, false],
    },
  ];
};

const Footer = (currentPage: number, text: any) => {
  if (currentPage > 1) {
    return [
      {
        text: '',
        margin: [0, 15, 0, 0],
      },
      {
        columns: [
          {
            width: "90%",
            text,
            alignment: "left",
            margin: [40, 0, 0, 0],
            style: ["text_small"],
          },
          {
            width: "5%",
            text: `${currentPage >= 10 ? currentPage : `0${currentPage}`}`,
            alignment: "right",
            margin: [0, 0, 10, 0],
            style: ["text_small"],
          },
        ],
      },
    ];
  }
  return [];
}

const Qualities = (reportManager: ReportManager) => {

  const {palette} = data.meta;

  //Generate a list of highest rated behaviours
  const sortedScores = sortBy(reportManager.reportAssessment?.assessDomains.flatMap(rd => rd.reportQuestions), 'resultsOtherAverage')

  const highest_rated_behaviours = highestScores(sortedScores, reportManager.delegate!, entity.value!)
  const lowest_rated_behaviours = lowestScores(sortedScores, reportManager.delegate!, entity.value!)

  const textReference = reportManager.delegate!.delegateType == DelegateType.Individual ? "you and your" : "the team's"
  const textPlural = reportManager.delegate!.delegateType == DelegateType.Individual ? "your" : "the team's"
  const textPluralCapital = reportManager.delegate!.delegateType == DelegateType.Individual ? "Your" : "The team's"

  const isIndividual = reportManager.delegate!.delegateType == DelegateType.Individual

  let section: any[] = [];

  try {
    let section_rows: any = [];
    let section_table: any[] = []
    let quality_body: any[] = [];

    reportManager.reportAssessment?.assessDomains.forEach((domain: ReportDomain, qidx: number) => {
      quality_body.push([
        {
          svg: header_svg(domain, qidx, isIndividual)
        },
      ]);

      domain.reportQuestions = domain.reportQuestions.sort((a, b) =>
          a.question.key.localeCompare(b.question.key))

      domain.reportQuestions.forEach(question => {
        const chartColor = domain.domain.colour;
        const chartTitle = question.question.title || "Not set";
        const scoreAvgOthers = question.resultsOtherAverage
        const scoreSelf = question.resultsMine
        const key = question.question.key

        quality_body.push([
          {
            svg: all_in_one_svg(
                key,
                chartColor,
                escape(chartTitle),
                scoreAvgOthers,
                scoreSelf ?? 0,
                isIndividual
            ),
          },
        ]);
      });

      section_rows.push({
        table: {
          widths: ["*"],
          body: [...quality_body],
        },
        layout,
      });
      quality_body = [];

      if (section_rows.length === 3) {
        section_table.push(section_rows);
        section_rows = [];
      }
    });

    const otherAverageScore = Math.round(reportManager.reportAssessment!.otherTotalAverage * 10) / 10

    const mineAverageScore = Math.round((reportManager.reportAssessment?.mineTotalAverage ?? 0) * 10) / 10

    const oneViewContent = {
      svg: drawLeadership360(reportManager.reportAssessment!, 1200, 5, "chartContainer"),
      width: 550,
      style: ['centerAligned'],
      margin: [0, 0, 0, 0],
    }

    let resultsTableHeadingContent = [
      [
        {
          text: `${isIndividual ? `${textPluralCapital} assessors’ average score\n\n` : 'Average Score'}`,
          style: [
            "primary",
            "section_description",
            "centerAligned",
          ],
        },
        {
          svg: generate_circle_svg({
            size: 2.75,
            color: "#831F27",
            text: `${otherAverageScore}/10`,
            font_size: 16
          }),
          style: ["centerAligned"],
        },
      ]
    ]

    if (reportManager.delegate?.delegateType == DelegateType.Individual) {
      resultsTableHeadingContent.push([
        {
          text: `${textPluralCapital} score\n\n`,
          style: [
            "primary",
            "section_description",
            "centerAligned",
          ],
        },
        {
          svg: generate_circle_svg({
            size: 2.75,
            color: colors['olive-green'],
            text: `${mineAverageScore}/10`,
            font_size: 16
          }),
          style: ["centerAligned"],
        },
      ])
    }

    section = [
      {
        text: "Section B: Dashboard",
        style: ["section_title"],
        pageBreak: "before",
        ...toc_style,
        tocStyle: {...toc_style.tocStyle, italics: true}
      },
      {
        text: `Your rating at a glance`,
        style: ["primary", "section_subtitle"],
        margin: [0, 10, 0, 15],
        ...toc_style,
        tocMargin: [10, 0, 0, 0],
      },
      {
        text: `The chart displays the ratings at a glance. This serves as a quick reference to graphically see where ${textPlural} strengths and developmental areas lie.`,
        style: ["primary", "section_description"],
        margin: [0, 0, 0, 10],
      },
      {
        text: `This chart shows a composite view of behaviour ratings (out of 10) from ${textReference} assessors for all ${30} statements as well as the average score for each of the six domains.`,
        style: ["primary", "section_description"],
        margin: [0, 0, 0, 10],
      },
      {
        text: `The average score for the whole assessment and average score per domain is assessors’ ratings only.`,
        style: ["primary", "section_description"],
        margin: [0, 0, 0, 20],
      },

      oneViewContent,

      {
        text: "Results table",
        style: ["primary", "section_subtitle"],
        margin: [0, 0, 0, 10],
        pageBreak: "before",
        ...toc_style,
        tocMargin: [10, 0, 0, 0],
      },
      {
        text: '',
        margin: [0, 0, 0, 20],
      },
      [
        {
          columns: resultsTableHeadingContent,
        },
      ],
      {
        text: `Scores are tabulated below for each domain and behavioural statement${isIndividual ? ', showing a comparison of your self-score and your assessors’ average score.' : '.'}`,
        style: ["primary", "section_description"],
        margin: [0, 20, 0, 10],
      },
      {
        table: {
          widths: ['*', '*', '*'],
          body: [...section_table]
        },
        margin: [-20, 0, 0, 0],
        layout: {
          ...layout,
          paddingBottom: function () {
            return 5;
          },
        }
      },
      {
        text: "",
        margin: [0, 5, 0, 0],
      },
      ScoreLegends(reportManager.delegate?.delegateType == DelegateType.Individual),
      HighestLowestScoreTable(
          palette,
          highest_rated_behaviours,
          lowest_rated_behaviours,
          true
      ),
    ]

  } catch (qualitiesSectionError) {
    console.error('Error generating Qualities Section', qualitiesSectionError)
  }

  return section;
}

const ScoreLegends = (both = true) => {
  let self_lengend: any = {}

  if (both) {
    self_lengend = {
      width: 150,
      table: {
        body: [
          [
            {
              svg: generate_circle_svg({
                size: 1.05,
                color: colors['olive-green'],
                text: "",
              }),
            },
            {
              text: `Self-score`,
              style: ["secondary", "text_small"],
              margin: [3, 10, 0, 0],
            },
          ],
        ],
      },
      layout,
    }
  }
  return {
    columns: [
      {
        width: both ? 110 : 175,
        text: "",
      },
      {
        width: 200,
        table: {
          body: [
            [
              {
                //This is where the statement was: color: both ? colors['wine-red'] : colors['olive-green'],
                svg: generate_circle_svg({
                  size: 1.05,
                  color: colors['wine-red'],
                  text: "",
                }),
              },
              {
                text: `Assessors' average score`,
                style: ["primary", "text_small"],
                margin: [3, 10, 0, 0],
              },
            ],
          ],
        },
        layout,
      },
      self_lengend
    ],
  };
}

const HighestLowestScoreTable = (
    palette: any,
    highest_rated_behaviours: any,
    lowest_rated_behaviours: any,
    is360: boolean = false
) => {
  return [
    {
      pageBreak: "before",
      text: "Highest-rated and lowest-rated behaviours",
      style: ["primary", "section_subtitle"],
      margin: [0, 0, 10, spacing.subsection],
      ...toc_style,
      tocMargin: [10, 0, 0, 0],
    },
    {
      margin: [0, 20, 0, 0],
      table: {
        headerRows: 1,
        widths: [240, 20, 240],
        body: [
          [
            {
              text: [
                {
                  text: "Highest-rated behaviours\n",
                  color: "#fff",
                  style: ["default", "section_description"],
                  alignment: "center",
                },
                {
                  text: is360
                      ? "How can you best influence others using these behaviours?"
                      : "How can you further grow your team using these behaviours?",
                  italics: true,
                  color: "#fff",
                  style: ["default", "roboto_light_italic", {fontSize: 9}],
                  alignment: "center",
                },
              ],
              fillColor: "#698C9A",
            },
            {
              text: "",
              style: ["default"],
              border: [false, false, false, false],
            },
            {
              text: [
                {
                  text: "Lowest-rated behaviours\n",
                  color: "#fff",
                  style: ["default", "section_description"],
                  alignment: "center",
                },
                {
                  text: `What new ${
                      is360 ? "habits" : "routines"
                  } are needed to address these behaviours?`,
                  italics: true,
                  color: "#fff",
                  style: ["default", "roboto_light_italic", {fontSize: 9}],
                  alignment: "center",
                },
              ],
              fillColor: "#698C9A",
            },
          ],
          [
            {
              table: {
                body: [
                  ...highest_rated_behaviours.map(
                      (entry: {
                        behaviour: { key: any; title: any; description: any, rating: any };
                      }) => {
                        return [
                          {
                            text: [
                              {
                                text: `${entry.behaviour.key} - Score: ${entry.behaviour.rating}\n`,
                                style: ["default", "section_description"],
                                alignment: "left",
                                margin: [5, 5, 5, 15],
                                bold: true,
                              },
                              {
                                text: `${entry.behaviour.title}\n`,
                                style: ["default", "primary"],
                                lineHeight: 1.2,
                                alignment: "left",
                                margin: [5, 5, 15, 5],
                              },
                              {
                                text: `${entry.behaviour.description}\n\n`,
                                style: ["default", "secondary"],
                                lineHeight: 1.2,
                                alignment: "left",
                                margin: [25, 5, 15, 10],
                                italics: true,
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ];
                      }
                  ),
                ],
              },
            },
            {
              text: "",
              style: ["default"],
              border: [false, false, false, false],
            },
            {
              table: {
                body: [
                  ...lowest_rated_behaviours.map(
                      (entry: {
                        behaviour: { key: any; title: any; description: any, rating: any };
                      }) => {
                        return [
                          {
                            text: [
                              {
                                text: `${entry.behaviour.key} - Score: ${entry.behaviour.rating}\n`,
                                style: ["default"],
                                alignment: "left",
                                margin: [5, 5, 5, 15],
                                bold: true,
                              },
                              {
                                text: `${entry.behaviour.title}\n`,
                                style: ["default", "primary"],
                                lineHeight: 1.2,
                                alignment: "left",
                                margin: [5, 5, 5, 5],
                              },
                              {
                                text: `${entry.behaviour.description}\n\n`,
                                style: ["default", "secondary"],
                                lineHeight: 1.2,
                                alignment: "left",
                                margin: [25, 5, 5, 10],
                                italics: true,
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ];
                      }
                  ),
                ],
              },
            },
          ],
        ],
      },
    },
  ];
}

const FeedbackSection = (reportManager: ReportManager) => {
  let margin = [0, 0, 0, 50]
  let your_rating_subsection: any = []
  const isIndividual = reportManager.delegate?.delegateType == DelegateType.Individual

  if (isIndividual) {
    margin = [0, 0, 50, 0]
    your_rating_subsection = [
      {
        text: "Self-score",
        margin: [0, 10, 0, 10],
        style: ["primary", "centerAligned", "section_description"],
      },
      {
        svg: generate_circle_svg({
          size: 1.5,
          color: colors['olive-green'],
          text: (Math.round((reportManager.reportAssessment?.welcomeTotalAverageMine ?? 0)  *10) / 10).toFixed(1),
          font_size: 12,
        }),
        style: ["primary", "centerAligned", "section_description"],
      }
    ]
  }

  let feelingComment = reportManager.reportAssessment?.welcomeOtherComments.map(wco => {
    return {
      isSelf: false,
      comment: wco
    }
  })

  if (reportManager.reportAssessment?.welcomeMineComment) {
    feelingComment!.push({
      isSelf: true,
      comment: reportManager.reportAssessment?.welcomeMineComment
    })
  }

  return [
    {
      text: "Section C: My Feedback",
      style: ["section_title"],
      pageBreak: "before",
      ...toc_style,
      tocStyle: {...toc_style.tocStyle, italics: true},
    },
    {
      text: "Relationship rating",
      margin: [0, 0, 0, 15],
      style: ["primary", "section_subtitle"],
      ...toc_style,
      tocMargin: [10, 0, 0, 0],
    },
    ResultsTableStatement(reportManager),
    {
      columns: [
        {
          width: "40%",
          margin,
          stack: [
            {
              text: "Assessors' average rating",
              style: [
                "primary",
                "centerAligned",
                "section_description",
                {lineHeight: 1.2},
              ],
              margin: [0, 0, 0, isIndividual ? 15 : 40],
            },
            {
              svg: generate_circle_svg({
                size: isIndividual ? 1.5 : 2.5,
                color: colors.blue,
                text: reportManager.reportAssessment?.welcomeTotalAverageOther!.toFixed(1),
                font_size: 12,
              }),
              style: ["centerAligned"],
            },
            ...your_rating_subsection
          ],
        },
        {
          width: "45%",
          stack: [
            {
              text: `Ratings count (assessors only)`,
              style: ["primary", "section_description", {lineHeight: 1.2}],
              margin: [0, 0, 0, 15],
            },
            {
              image: "feedbackGraph",
              width: 300,
              alignment: "left",
              margin: [0, 0, 0, 0],
              border: [false, false, false, false],
            },
          ],
        },
      ],
    },
    {
      text: "",
      margin: [0, 0, 0, 15],
    },
    {
      table: {
        widths: ["100%"],
        headerRows: 1,
        dontBreakRows: false,
        body: [
          [
            {
              text: `Reasons for the above ratings`,
              style: ["primary", "section_description"],
              border: [false, false, false, false],
              margin: [0, 5, 0, 5],
            },
          ],
          [{stack: CommentSection(feelingComment, reportManager)}],
        ],
      },
    },
  ];
}

const BehaviouralFeedback = (reportManager: ReportManager) => {
  const isIndividual = reportManager.delegate?.delegateType == DelegateType.Individual

  const assessmentCommentsAway = reportManager.reportAssessment!.awayOtherComments.map(away => {
    return {
      comment: away,
      isSelf: false
    }
  })

  if (reportManager.reportAssessment?.awayMineComment) {
    const mine = {
      comment: reportManager.reportAssessment?.awayMineComment,
      isSelf: true
    }

    assessmentCommentsAway.push(mine)
  }

  const assessmentCommentsToward = reportManager.reportAssessment!.towardOtherComments.map(toward => {
    return {
      comment: toward,
      isSelf: false
    }
  })

  if (reportManager.reportAssessment?.towardMineComment) {
    assessmentCommentsToward.push({
      comment: reportManager.reportAssessment?.towardMineComment,
      isSelf: true
    })
  }

  const awayLabel = isIndividual ? `Which behaviours does the leader display that put you in an AWAY state, leaving you feeling switched off?` : `Which behaviours in the team put you in an AWAY state, leaving you feeling switched off?`

  const towardLabel = isIndividual ? `Which behaviours does the leader display that put you in a TOWARD state, leaving you feeling switched on?` : `Which behaviours in the team put you in a TOWARD state, leaving you feeling switched on?`

  return [
    {
      pageBreak: "before",
      text: "Behavioural feedback",
      style: ["primary", "section_subtitle"],
      margin: [0, 0, 0, 10],
      ...toc_style,
      tocMargin: [10, 0, 0, 0],
    },
    {
      text: [
        {
          text: `Neuroscience shows that in a relationship, certain behaviours from one person invoke a reward response, placing the other person in a`,
        },
        {
          text: ` TOWARD STATE (leaving them feeling energised, engaged and switched on).`,
          style: ["secondary"],
        },
      ],
      style: ["primary", "section_description", {alignment: "justify"}],
    },
    {
      text: [
        {
          text: `Conversely, certain behaviours activate a threat response and put the other person in an`,
        },
        {
          text: ` AWAY STATE (leaving them feeling frustrated, disengaged and switched off).`,
          style: [{color: colors['wine-red']}],
        },
      ],
      margin: [0, 5, 0, 10],
      style: ["primary", "section_description", {alignment: "justify"}],
    },
    {
      table: {
        widths: ['100%'],
        headerRows: 1,
        dontBreakRows: false,
        body: [
          [
            {
              text: towardLabel,
              style: ["secondary", "section_description"],
              border: [false, false, false, false],
              margin: [0, 5, 0, 5],
            }
          ],
          [{stack: CommentSection(assessmentCommentsToward, reportManager)}]
        ],
      },
    },
    {
      text: ``,
      margin: [0, 20, 0, 0],
    },
    {
      table: {
        widths: ['100%'],
        headerRows: 1,
        dontBreakRows: false,
        body: [
          [
            {
              text: awayLabel,
              style: ["primary", "section_description", {color: colors['wine-red']}],
              border: [false, false, false, false],
              margin: [0, 5, 0, 5],
            }
          ],
          [{stack: CommentSection(assessmentCommentsAway, reportManager)}]
        ],
      },
    },
  ];
}

const InterpretingFeedback = (reportManager: ReportManager) => {

  const isIndividual = reportManager.delegate?.delegateType == DelegateType.Individual
  const body_rows: any = [];


  reportManager.reportAssessment?.assessDomains.forEach(domain => {
    const section = domain.domain.name.replace(' ', '_').toLocaleLowerCase();

    const {shortStatement, behaviourChanges} = behaviour_leading_questions[`${section}`]

    const key = domain.domain.key

    const behaviourTitle: any = []

    const chartColor = domain.domain.colour

    domain.reportQuestions.forEach(question => {
      const chart_title = question.question.title || "Not set";

      behaviourTitle.push(`${question.question.key}. ${chart_title}\n`)
    });

    body_rows.push([
      {
        text: `${key}. ${domain.domain.name}`,
        fillColor: chartColor,
        style: [
          "section_subtitle",
          "centerAligned",
          {color: contrastingColor(chartColor)},
        ],
        margin: [0, 60, 0, 0],
      },
      {
        stack: [
          {
            text: shortStatement,
            italics: true,
            margin: [0, 5, 0, 25],
            style: ["section_description"],
          },
          {
            text: behaviourTitle,
            margin: [1, 10],
            style: ["comments", {lineHeight: 1.2}],
          },
        ],
        fillColor: chartColor,
        style: [
          "primary",
          "text_small",
          {color: contrastingColor(chartColor)},
        ],
      },
      {
        ul: behaviourChanges.map(question => {
          return {
            text: question,
            style: [!question.includes('-')]
          };
        }),
        style: ["primary", "comments"],
        margin: [10, 10],
      },
    ]);
  });
  const things_to_consider = isIndividual ? [
    `What patterns or underlying beliefs might be fueling the behaviours that hinder you and your team?`
  ] : [
    `Is there a pattern or underlying belief behind those behaviours that hinder the team?`,
    `How consistent is the spread of assessors' ratings per domain?`,
    `Which team behaviours require focus now?`
  ]
  return [
    {
      text: "Section D: Interpreting my Feedback",
      style: ["section_title"],
      pageBreak: "before",
      ...toc_style,
      tocStyle: {...toc_style.tocStyle, italics: true},
    },
    {
      text: "Stages of behavioural change",
      style: ["primary", "section_subtitle"],
      ...toc_style,
      margin: [0, 0, 0, spacing.subsection],
      tocMargin: [10, 0, 0, 0]
    },
    {
      text: [
        "Purposeful behavioural change happens in three stages: from developing self-awareness; to committing to the change; and then to practising new behaviours until they become subconscious habits. Your feedback ",
        "results enable you to kick-start this journey by providing new insights that in turn help you to refine your leadership ",
        "purpose and then embed habits to serve that purpose.",
      ],
      style: [
        "primary",
        "section_description",
        {alignment: "justify"},
      ],
      margin: [0, 0, 0, 30],
    },
    {
      image: 'reportBehaviourChange',
      width: 600,
      margin: [0, 10, 0, 0],
      style: ["centerAligned"],
    },
    {
      text: "Leveraging assessment scores",
      style: ["primary", "section_subtitle"],
      pageBreak: "before",
      ...toc_style,
      tocMargin: [10, 0, 0, 0],
    },
    {
      text: [
        "The table below presents the core themes that are tested in each iVolve domain. Use the leading questions ",
        "below to consider new behaviours and habits that will counteract your lowest-rated behaviours.",
      ],
      style: [
        "primary",
        "section_description",
      ],
      margin: [0, 10, 0, 15],
    },
    {
      table: {
        headerRows: 1,
        dontBreakRows: true,
        keepWithHeaderRows: true,
        widths: [60, 140, "*"],
        body: [
          [
            {
              text: "iVolve domain",
              fillColor: "#808080",
              alignment: "center",
              color: "white",
              margin: [0, 8, 0, 8],
            },
            {
              text: "Core qualities",
              fillColor: "#808080",
              alignment: "center",
              color: "white",
              margin: [0, 12, 0, 8],
            },
            {
              text: "Behaviours to consider",
              fillColor: "#808080",
              alignment: "center",
              color: "white",
              margin: [0, 12, 0, 0],
            },
          ],
          ...body_rows,
        ],
      },
    },
    {
      text: "Using my feedback",
      style: ["primary", "section_subtitle"],
      pageBreak: "before",
      ...toc_style,
      tocMargin: [10, 0, 0, 0],
    },
    {
      text: [
        `From this report, you have a number of reference areas from which to identify ${isIndividual ? "leadership" : "team"} behaviours you wish `,
        `to change.`
      ],
      style: [
        "primary",
        "section_description",
      ],
      margin: [0, 10, 0, 15],
    },
    {
      text: `Consider the following:`,
      style: [
        "primary",
        "section_description",
      ],
      margin: [0, 0, 0, 10],
    },
    {
      ul: things_to_consider,
      style: ["primary", "section_description"],
      margin: [10, 0, 0, 10],
    },
    ...[
      `Which hindering behaviours require focus now?`,
      `What are the triggers or situations associated with these behaviours?`,
      `What new behaviours do you wish to adopt to counter behaviours that hinder you and your team?`,
      isIndividual ?
          `Select ONE behaviour that you want to change now. What routines will you put in place to practise and embed this new behaviour so it becomes a habit?` :
          `Select ONE behaviour that you want the team to change now. What routines can the team put in place to practice and embed this new behaviour until it becomes a habit?`,
    ].map(text => {
      return {
        table: {
          widths: ["100%"],
          heights: 100,
          body: [
            [
              {
                text: text,
                style: [
                  "primary",
                  "section_description",
                ],
              },
            ],
          ],
        },
        margin: [0, 0, 0, 10],
      };
    }),
    {
      text: [
        `Thank you for trusting iVolve with this feedback process. We trust that you will gain much value from it and that it will support your growth as a ${isIndividual ? 'leader' : 'team'}.`
      ],
      style: [
        "primary",
        "section_description",
      ],
      margin: [0, 80, 0, 15],
    }
  ];
}

const iVolveBehaviourSection = async (reportManager: ReportManager): Promise<any[]> => {
  const isIndividual = reportManager.delegate?.delegateType == DelegateType.Individual;

  const domainAvgScoreRow = (selfScore: number, otherScore: number) => {
    if (!isIndividual) {
      return {
        columns: [
          {
            width: '15%',
            text: '',
          },
          {
            width: 200,
            table: {
              body: [
                [
                  {
                    svg: generate_circle_svg({
                      size: 2,
                      color: colors['wine-red'],
                      text: selfScore.toFixed(1),
                      font_size: 12,
                    }),
                  },
                  {
                    text: `Average score`,
                    style: ["primary", "section_description"],
                    margin: [10, 25, 0, 0],
                  },
                ],
              ],
            },
            layout,
          },
        ],
        margin: [0, 0, 0, 20],
      }
    } else {
      return {
        columns: [
          {
            width: '15%',
            text: '',
          },
          {
            width: '35%',
            table: {
              body: [
                [
                  {
                    svg: generate_circle_svg({
                      size: 1.25,
                      color: colors['wine-red'],
                      text: selfScore.toFixed(1),
                      font_size: 12
                    })
                  },
                  {
                    text: `Assessors' average score`,
                    style: ["primary", "comments"],
                    margin: [2, 15, 0, 0],
                  },
                ],
              ],
            },
            layout,
          },
          {
            width: "45%",
            table: {
              body: [
                [
                  {
                    svg: generate_circle_svg({
                      size: 1.25,
                      color: colors['olive-green'],
                      text: otherScore.toFixed(1),
                      font_size: 12
                    }), margin: [10, 0, 0, 0]
                  },
                  {
                    text: `Self-score`,
                    style: ["primary", "comments"],
                    margin: [2, 15, 0, 0],
                  },
                ],
              ],
            },
            layout,
          },
        ],
      }
    }
  }

  const behaviourSection: any[] = [
    {
      text: "Detailed Results",
      style: ["secondary"],
      pageBreak: "before",
      ...toc_style,
      tocMargin: [10, 0, 0, 0],
    },
  ];

  let pageBreak = false

  for (const domain of reportManager.reportAssessment?.assessDomains!) {
    const domainOtherAverageScore = domain.otherDomainAverage
    const domainMineAverageScore = domain.mineDomainAverage ?? 0

    let section_content = [
      {
        text: `${domain.domain.key}. ${domain.domain.name}`,
        style: ["section_subtitle", "primary"],
        ...toc_style,
        tocMargin: [20, 0, 0, 0],
        pageBreak: pageBreak ? "before" : '',
        margin: [0, 0, 0, 5],
      },
      domainAvgScoreRow(domainOtherAverageScore, domainMineAverageScore),
    ]

    pageBreak = true

    behaviourSection.push(...section_content);

    const charts = await annexureTableBarCharts(isIndividual, domain)

    behaviourSection.push(charts);
    behaviourSection.push({text: '', margin: [0, 0, 0, 5]})

    domain.reportQuestions.forEach(question => {
      const thirdStatement = question.question.statements.find(st => st.statementPerspectiveType == StatementPerspectiveType.Third)

      const head = UserAssessmentExtensions.parseText(thirdStatement!.head!, reportManager.delegate!, entity.value!, reportManager.delegate?.individual)
      const heart = UserAssessmentExtensions.parseText(thirdStatement!.heart!, reportManager.delegate!, entity.value!, reportManager.delegate?.individual)

      behaviourSection.push({
        text: [`${question.question.key}`],
        lineHeight: 1.2,
        style: ["default"],
      });
      behaviourSection.push({
        text: [`${head}`],
        style: ["default", "primary"],
        lineHeight: 1.2,
      });
      behaviourSection.push({
        text: `${heart}`,
        style: ["default", "secondary"],
        lineHeight: 1.2,
        italics: true,
        margin: [0, 0, 0, 7],
      });
    });

    const comments = domain.otherComments.map(d => {
      return {
        comment: d,
        isSelf: false
      }
    })

    if (isIndividual && domain.mineComment != "") {
      comments.push({
        comment: domain.mineComment,
        isSelf: true
      })
    }

    behaviourSection.push({
      pageBreak: "before",
      table: {
        widths: ['100%'],
        headerRows: 1,
        dontBreakRows: false,
        body: [
          [
            {
              text: "Assessors' comments",
              style: ['primary', "section_description"],
              border: [false, false, false, false],
              margin: [0, 5, 0, 5],
            }
          ],
          [{stack: CommentSection(comments, reportManager)}]
        ],
      },

    })
  }

  return behaviourSection;
}

const DefaultHeader = (currentPage: number, pageCount: number) => {
  if (currentPage > 1) {
    return [
      {
        image: "partnerAvatar",
        alignment: "right",
        width: 25,
        margin: [0, 20, 20, 20],
      }
    ];
  }
  return [];
}

const annexureTableBarCharts = async (isIndividual: boolean, domain: ReportDomain) => {
  const domainGraphs = graphs.value.find(d => d.key == domain.domain.key)!

  return {
    table: {
      headerRows: 0,
      widths: [490],
      body: [
        [
          {
            margin: [0, 5, 0, 0],
            text: [
              {
                text: `${isIndividual ? 'Self vs a' : 'A'}ssessors' scores\n`,
                style: ['primary', "section_description"]
              },
              {text: `(Rounded to one decimal point)`, style: ['secondary', "text_small"]}
            ],
            border: [false, false, false, false],
            alignment: 'left'
          }
        ],
        [
          {
            image: domainGraphs.selfVsAssessor,
            width: 490,
            alignment: 'center',
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
          }
        ],
        [
          {
            text: [
              {
                text: `Ratings count\n`,
                style: ['primary', "section_description"]
              },
              {
                text: `${isIndividual ? '(Assessors only)' : ''}`,
                style: ['secondary', "text_small"]
              }
            ],
            border: [false, false, false, false],
            alignment: 'left'
          }
        ],
        [
          {
            image: domainGraphs.ratingGraph,
            width: 490,
            alignment: 'center',
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
          }
        ]
      ],
      layout: 'noBorders'
    }
  }
}

const ResultsTableStatement = (reportManager: ReportManager) => {
  const isIndividual = reportManager.delegate?.delegateType == DelegateType.Individual

  const yourself = isIndividual ? `Yourself: "How do you rate your general working relationships at ${userStore.state?.activeEntity.displayName}?"` : ''

  const assessors = isIndividual ? `How do you rate your working relationship with ${reportManager.delegate?.individual?.firstName}?` : `How do you rate your general working relationships with the ${reportManager.delegate?.displayName} team?`

  return {
    stack: [
      {text: `Assessors: "${assessors}"`},
      {text: `${yourself}`},
      {text: `(1 = Toxic; 10 = Outstanding)`, style: ["comments"]}
    ],
    style: ["primary", "section_description"],
    margin: [0, 0, 0, 20],
  }
}

const CommentSection = (comments: { comment: string, isSelf: boolean }[] = [], reportManager: ReportManager) => {
  const _comments = comments.map(({comment, isSelf}) => {
    const name = isSelf ? trim(reportManager.delegate?.individual?.firstName) : undefined

    return {
      text: isSelf && name ? `(${name}) - "${comment}"` : `"${comment}"`,
      style: [isSelf ? {color: '#7D983C'} : 'primary', "section_description"],
      margin: [0, 0, 0, 8]
    }
  })
  return [{text: '', margin: [0, 10, 0, 0]}, ..._comments, {text: '', margin: [0, 0, 10, 0]}]
}
</script>

  