<template>
  <div v-if="entity">
    <Card class="text-payne-grey text-sm-body-1 flex flex-col space-y-8 text-center">
      <div class="flex justify-center items-center">
        <img :src="EntityExtensions.getCompanyLogo(entity)" alt="Logo" class="h-12 md:h-16">
      </div>

      <div>
        {{ parseText(props.assessmentType.welcomeMessage) }}
      </div>

      <div>
        <img src="/img/report_framework.png" alt="Result Framework"/>
      </div>

      <div>
        You will rate 30 behavioural statements spread across six sections. A 10-point scale is used to rate how often
        you observe the behaviour described for each statement.
      </div>

      <table class="min-w-full divide-y divide-gray-300 border border-payne-grey font-roboto text-payne">

        <thead class="bg-box-bg font-bold text-base leading-5 border-payne-grey">

        <tr>
          <th scope="col" class="py-3.5 px-3 text-left text-payne">Rating</th>
          <th scope="col" class="px-3 py-3.5 text-left text-payne">Description</th>
        </tr>

        </thead>

        <tbody>
        <tr v-for="description of ratingDescriptions" :key="description.score" class="text-left">
          <td class="font-semibold py-2 px-3 border-payne-grey border">
            {{ description.scoreHeading }} <br/>
            {{ description.score }}
          </td>

          <td class="px-3 border-payne-grey border">
            {{ description.description }}
          </td>
        </tr>
        </tbody>
      </table>

      <div class="text-xs">
        By continuing this assessment, you unconditionally and voluntarily consent to processing of
        your submitted personal information (name, surname, email, employee data and feedback opinions)
        by iVolve (Pty) Ltd for purposes of this leadership behavioural assessment and for the following uses: <br/>
        (i) Preparation of the Assessment report for the delegate and nominated {{ entity.displayName }} personnel. <br/>
        (ii) Storing of assessment data for {{ entity.displayName }} talent management processes. <br/>
        (iii) Trend analysis of de-identified (anonymous) data. <br/>
      </div>

    </Card>
  </div>
</template>

<script setup lang="ts">

import {AssessmentTypeDto} from "@/areas/assessmentTypes/model/data/dto/AssessmentTypeDto";
import Card from "@/shared/components/Card.vue";
import {UserAssessmentExtensions} from "../extensions/UserAssessmentExtensions";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {useUserStore} from "@/areas/users/store/userStore";
import {onMounted, ref} from "vue";
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import {EntityExtensions} from "../../entities/extensions/EntityExtensions";

interface RatingScale {
  score: string
  scoreHeading: string
  description: string
}

const props = defineProps<{
  delegate: DelegateDto
  assessmentType: AssessmentTypeDto
}>()

const userStore = useUserStore()

const entity = ref<Entity | undefined>(undefined)

const ratingDescriptions = ref<RatingScale[]>([
  {
    score: "Score of 1",
    scoreHeading: "Never",
    description: "The statement does not apply at all under any circumstances."
  },
  {
    score: "Score of 2",
    scoreHeading: "Almost never",
    description: "The statement applies in extremely rare or exceptional cases."
  },
  {
    score: "Score of 3",
    scoreHeading: "Rarely",
    description: "The statement applies in a few isolated instances."
  },
  {
    score: "Score of 4",
    scoreHeading: "Occasionally",
    description: "The statement applies from time to time but is not a common occurrence."
  },
  {
    score: "Score of 5",
    scoreHeading: "Sometimes",
    description: "The statement applies about half the time, it is as likely to be true as not."
  },
  {
    score: "Score of 6",
    scoreHeading: "Fairly often",
    description: "The statement applies more often than not, it is a regular occurrence but not predominant."
  },
  {
    score: "Score of 7",
    scoreHeading: "Often",
    description: "The statement applies most of the time, it is a frequent and notable behaviour."
  },
  {
    score: "Score of 8",
    scoreHeading: "Usually",
    description: "The statement applies almost all the time, it is a habitual and consistent behaviour."
  },
  {
    score: "Score of 9",
    scoreHeading: "Almost always",
    description: "The statement applies nearly every time, deviations are rare and exceptional."
  },
  {
    score: "Score of 10",
    scoreHeading: "Always",
    description: "The statement applies every single time without exception."
  },
])

const parseText = (someString: string): string => {
  return UserAssessmentExtensions.parseText(someString, props.delegate, entity.value!, props.delegate.individual)
}

onMounted(() => {
  entity.value = userStore.state?.activeEntity!
})
</script>