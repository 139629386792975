<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="closeOnContentClick" location="start">
      <template v-slot:activator="{ props }">
        <span v-bind="props">
          <slot name="popover_activator"></slot>
        </span>
      </template>

      <v-card class="min-w-[200px]" @click="closeOnClick ? closePopover : () => {}" v-click-outside="clickedOutside">
        <v-list>
          <slot></slot>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script lang="ts">
import {defineComponent, onBeforeUnmount, onMounted, ref} from 'vue';
//@ts-ignore
import vClickOutside from 'click-outside-vue3'
import bus from '@/bus'

export default defineComponent({
  directives: {
    clickOutside: vClickOutside.directive
  },
  emits: {
    popOverClosed: null
  },
  props: {
    closeOnClick: {
      type: Boolean,
      default: false
    },
    closeOnContentClick: {
      type: Boolean,
      default: false
    }
  },

  setup(_, {emit}) {
    const menu = ref<boolean>(false)
    const isModalOpen = ref<boolean>(false)

    const closePopover = () => {
      menu.value = false
    }

    onMounted(() => {
      bus.on('close-popover-menu', closePopover)
      bus.on('modal-opened', () => isModalOpen.value = true)
    })

    onBeforeUnmount(() => {
      bus.off('close-popover-menu', closePopover)
      bus.off('modal-opened', () => isModalOpen.value = false)

    })

    const clickedOutside = () => {
      if (isModalOpen.value === true) {
        closePopover()
        emit('popOverClosed')
      }
    }
    return {
      menu,
      clickedOutside,
      closePopover
    }
  }
})
</script>